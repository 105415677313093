import { ErrorMessage, Field, Form, Formik } from 'formik';
import Breadcrumb from '../../components/Breadcrumb';
import OptionService from '../../services/OptionService';
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useParams, Params, Link } from 'react-router-dom';
import AddOptionMediaImage from '../../components/AddOptionMediaImage';

interface Media{
    mediaId:string;
    mediaUrl:string;
}

interface OptionInterface{
    optionId?:any|null;
    name:string;
    price:string;
    productName:string;
    mediaUrls:Media[];
}

const OptionMediaImages: React.FC<{}> = () => {
  const { optionId } = useParams<Params>(); 
  const [option, setOption] = React.useState<OptionInterface>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (optionId != undefined) {
          const response: AxiosResponse<OptionInterface> =
            await OptionService.getOption(optionId);
          const responseData: OptionInterface = response.data;
          setOption(responseData);
        } else {
          console.log('Option ID is not defined');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const [addImage, setAddImage] = useState(false);
  const [updateMediaImage, setUpdateMediaImage] = useState(false);


  return (
    <>
      <Breadcrumb pageName="Option Gallary Images" />
      <div className='px-5 py-4 bg-white '>
        <div className='flex space-x-5'>
      <h3 className="font-medium text-black text-xl dark:text-white mb-5"><b>Option:</b> {option?.name}</h3>
      <h3 className="font-medium text-black text-xl dark:text-white mb-5"><b>Product name:</b> {option?.productName}</h3>

      </div>
            <div className="flex flex-col  justify-center ">
            <div className="flex justify-between  w-full" >

            {option?.mediaUrls.map((media, index) => (
                <div>
                <div className="flex mt-5 p-1 backdrop-blur w-50 " key={index}>
                  <img
                    src={media?.mediaUrl}
                    alt="product"
                    className=" "
                  />
                </div>

                <div className="flex items-center justify-center w-50 h-8 xl:mt-8 mt-12 cursor-pointer gap-2 rounded bg-primary py-1.5 px-2 text-sm font-medium text-white hover:bg-opacity-80 xsm:px-4">
                  <button
                    type="submit"
                    onClick={() => setUpdateMediaImage(true)}
                    className="flex space-x-1"
                  >
                    <svg
                      className="fill-current mt-0.5"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638ZM2.625 4.66663H4.75C4.94439 4.66663 5.12614 4.76353 5.23464 4.9258L6.22814 6.41663H10.7719L11.7654 4.9258C11.8739 4.76353 12.0556 4.66663 12.25 4.66663H13.0833C13.2487 4.66663 13.4066 4.72875 13.5192 4.83748C13.6318 4.94621 13.6903 5.09291 13.6833 5.2433L12.8276 11.0037C12.7555 11.4467 12.3443 11.75 11.8958 11.75H2.10416C1.65568 11.75 1.24446 11.4467 1.17239 11.0037L0.316686 5.2433C0.309722 5.09291 0.368198 4.94621 0.480796 4.83748C0.593395 4.72875 0.75128 4.66663 0.916664 4.66663H2.625ZM11.0833 12.8333H2.91667V11.9166H11.0833V12.8333Z"
                        fill="#ffffff"
                      />
                    </svg>
                    <span>Update Image</span>
                  </button>
                  {/* {updateMediaImage && (
                    <UpdateProductMediaImage
                      onCancel={() => setUpdateMediaImage(false)}
                      mediaId={media.mediaId}
                      productId={product.productId}
                    />
                  )} */}
                </div>
              </div>
            ))}
            </div>

            <div className='flex  justify-center'>
            {option?.mediaUrls?.length == undefined ||
              (option?.mediaUrls?.length < 5 && (
                <div className="flex  justify-center w-80 h-8 xl:mt-10  mt-12 cursor-pointer gap-2 rounded bg-primary py-1.5 px-2 text-sm font-medium text-white hover:bg-opacity-80 xsm:px-4">
                <button
                  type="submit"
                  onClick={() => setAddImage(true)}
                  className="flex space-x-1 "
                >
                  <svg
                    className="fill-current mt-0.5 "
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638ZM2.625 4.66663H4.75C4.94439 4.66663 5.12614 4.76353 5.23464 4.9258L6.22814 6.41663H10.7719L11.7654 4.9258C11.8739 4.76353 12.0556 4.66663 12.25 4.66663H13.0833C13.2487 4.66663 13.4066 4.72875 13.5192 4.83748C13.6318 4.94621 13.6903 5.09291 13.6833 5.2433L12.8276 11.0037C12.7555 11.4467 12.3443 11.75 11.8958 11.75H2.10416C1.65568 11.75 1.24446 11.4467 1.17239 11.0037L0.316686 5.2433C0.309722 5.09291 0.368198 4.94621 0.480796 4.83748C0.593395 4.72875 0.75128 4.66663 0.916664 4.66663H2.625ZM11.0833 12.8333H2.91667V11.9166H11.0833V12.8333Z"
                      fill="#ffffff"
                    />
                  </svg>
                  <span className="hidden xl:inline-block">
                    Add Gallary image
                  </span>
                  <span className="xl:hidden ">Add Image</span>
                </button>
                {addImage && (
                  <AddOptionMediaImage
                    onCancel={() => setAddImage(false)}
                    optionId={optionId}
                  />
                )}
                </div>
              ))}
          </div>
          </div>
          </div>
            
    </>
  );
};

export default OptionMediaImages;
