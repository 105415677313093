import { Params, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_KEY, API_SECRET, BASEURL } from '../Constants';

interface OrderDetails {
  orderDetailsId?: number | null;
  orderId: number;
  price: string;
  productId: number;
  productImageUrl: string;
  productName: string;
  quantity: string;
  option: string;
}
interface orderInterface {
  orderId?: any | null;
  amount: string;
  date: string;
  customerId: string;
  shippingAddress: string;
  flatShipping: string;
  pincode: string;
  state: string;
  paymentMode: string;
  paymentId: string;
  paymentOrderId: string;
  paymentStatus: string;
  status: string;
  orderTotal: string;
  orderDetails: OrderDetails[];
}

interface AddressInterface {
  addressId?: number | null;
  locality: string;
  streetName: string;
  city: string;
  pin: string;
  state: string;
  country: string;
}

interface CustomerInterface {
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  companyName: string;
  addresses: AddressInterface[];
}
const OrderDetails = () => {
  const { orderId } = useParams<Params>();
  const [order, setOrder] = useState<orderInterface>();
  const [customer, setCustomer] = useState<CustomerInterface>();
  const [isLoading, setIsLoading] = useState(true);

  const headers = {
    //   Authorization: `Bearer ${accessToken}`,
    //   'Content-Type': 'multipart/form-data',
    'X-API-SECRET': `${API_SECRET}`,
    'X-API-KEY': `${API_KEY}`,
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (orderId != undefined) {
          const response = await axios.get(`${BASEURL}orders/${orderId}`, {
            headers: headers,
            method: 'GET',
          });
          const responseData = response.data;
          console.log('order', responseData);
          const customerResponse = await axios.get(
            `${BASEURL}customers/${responseData.customerId}`,
            {
              headers: headers,
              method: 'GET',
            },
          );
          setCustomer(customerResponse.data);
          console.log(responseData);
          setOrder(responseData);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const orderCompleted = async () => {
    const response = await axios.get(`${BASEURL}orders/completed/${orderId}`, {
      headers: headers,
    });
    window.location.reload();
  };

  const orderShipped = async () => {
    const response = await axios.get(`${BASEURL}orders/shipped/${orderId}`, {
      headers: headers,
    });
    window.location.reload();
  };

  return (
    <>
      <Breadcrumb pageName="Order Details" />
      {isLoading ? (
        <div className="flex mt-60 items-center justify-center">
          <svg
            className="h-10 w-10 animate-spin text-primary fill-primary"
            xmlns="http://www.w3.org/2000/svg"
            fill="primary"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="primary"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0117.709 7H15v5.373L6.373 17H6v-4.709zM20 12h-4v-.373l-5.373-5.372L15 6.627V2h5.373A8.001 8.001 0 0120 12z"
            ></path>
          </svg>
        </div>
      ) : order ? (
        <div className="">
          <div className="hidden xl:flex mb-2 justify-between">
            <div className="flex xl:flex-none ">
              <div>
                <h2 className="text-lg md:text-sm mr-2  xl:text-lg font-medium text-heading  ">
                  Order ID: {order.orderId}
                </h2>
                <h2 className="text-lg xl:mr-10 md:text-sm xl:text-lg font-medium text-heading ">
                  Date : {order.date.toString().split('T')[0]}
                </h2>
              </div>

              <div>
                <h2 className="text-lg md:text-sm mr-2 xl:mr-10 xl:text-base font-medium text-heading">
                  Status : {order.status}
                </h2>

                {order.paymentMode && (
                  <h2 className="text-lg md:text-sm mr-2 xl:mr-10 xl:text-base font-medium text-heading">
                    Payment Type :{' '}
                    {order.paymentMode === 'full' ? 'Full Payment' : 'COD'}
                  </h2>
                )}
              </div>

              <h2 className="text-lg md:text-sm mr-2 xl:mr-10 md:mr-6 xl:text-base font-medium text-heading">
                Customer: {customer?.firstName} {customer?.lastName}
              </h2>

              <h2 className="text-lg md:text-sm mr-2 xl:mr-10 xl:text-base font-medium text-heading">
                Contact No.: {customer?.contactNumber}
              </h2>
            </div>

            {order.status === 'ORDER_PLACED' ||
            order.status === 'ORDER_SHIPPED' ? (
              <div>
                <button
                  onClick={orderCompleted}
                  className=" ml-16 inline-block rounded bg-success px-4 py-1 text-sm font-medium text-gray hover:bg-opacity-90"
                >
                  <p>Mark as</p>
                  <p>Order Completed</p>
                </button>
              </div>
            ) : (
              order.status === 'PAYMENT_DONE' && (
                <div>
                  <button
                    onClick={orderShipped}
                    className=" ml-24 inline-block rounded bg-success px-4 py-1 text-sm font-medium text-gray hover:bg-opacity-90"
                  >
                    <p>Mark as</p>
                    <p>Order Shipped</p>
                  </button>
                </div>
              )
            )}
          </div>

          <div className="hidden xl:flex">
            <table className=" w-full bg-white mt-3">
              <thead>
                <tr className="bg-gray-500 text-left dark:bg-meta-4 border-b border-[#eee] ">
                  <th className="px-4 py-5 pl-5 font-semibold text-black dark:text-white xl:pl-11">
                    Product
                  </th>

                  <th className=" px-4 py-5 pl-9 font-semibold text-black dark:text-white">
                    Option
                  </th>

                  <th className=" px-4 py-5 pl-9 font-semibold text-black dark:text-white">
                    Price
                  </th>

                  <th className="px-4 py-5  font-semibold text-black dark:text-white">
                    Quantity
                  </th>

                  <th className="px-4 py-5  font-semibold text-black dark:text-white">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {order &&
                  order.orderDetails.map((order: any, key: any) => (
                    <tr className="border-b border-[#eee]">
                      <td className="border-b border-[#eee]  py-5 pl-9 dark:border-strokedark ">
                        <div className="sm:flex items-center gap-5 ">
                          <img
                            src={
                              order.productImageUrl ??
                              '/assets/placeholder/order-product.svg'
                            }
                            width="64"
                            height="64"
                            className="object-cover"
                          />{' '}
                          <div>{order.productName}</div>
                        </div>
                      </td>

                      <td className="border-b border-[#eee] px-4 py-5 pl-9 dark:border-strokedark ">
                        {order.option ? order.option : 'Not Available'}
                      </td>

                      <td className="border-b border-[#eee] px-4 py-5 pl-9 dark:border-strokedark ">
                        {order.price}
                      </td>

                      <td className="border-b border-[#eee] px-4 py-5  dark:border-strokedark ">
                        {order.quantity}
                      </td>

                      <td className="border-b border-[#eee] px-4 py-5  dark:border-strokedark ">
                        ₹. {order.amount}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="xl:hidden sm:block ">
            <div className="flex justify-between mb-4">
              <div className="space-y-0.5 text-base">
                <h2 className="text-base md:text-sm mr-2  xl:text-lg font-medium text-heading  ">
                  Order ID: {order.orderId}
                </h2>

                <h2 className=" md:text-sm mr-2 xl:mr-10 xl:text-base font-medium text-heading">
                  Status : {order.status}
                </h2>

                {order.paymentMode && (
                  <h2 className=" md:text-sm mr-2 xl:mr-10 xl:text-base font-medium text-heading">
                    Payment Type :{' '}
                    {order.paymentMode === 'full' ? 'Full Payment' : 'COD'}
                  </h2>
                )}

                <h2 className=" md:text-sm mr-2 xl:mr-10 xl:text-base font-medium text-heading">
                  Customer: {customer?.firstName} {customer?.lastName}
                </h2>

                <h2 className=" md:text-sm mr-2 xl:mr-10 xl:text-base font-medium text-heading">
                  Contact No.: {customer?.contactNumber}
                </h2>
              </div>
              <div className="">
                {order.status === 'ORDER_PLACED' ||
                order.status === 'ORDER_SHIPPED' ? (
                  <div>
                    <button
                      onClick={orderCompleted}
                      className="  inline-block rounded bg-success px-3 py-2  text-xs font-medium text-gray hover:bg-opacity-90"
                    >
                      <p>Mark as</p> <p> Order Completed</p>
                    </button>
                  </div>
                ) : (
                  order.status === 'PAYMENT_DONE' && (
                    <div>
                      <button
                        onClick={orderShipped}
                        className=" inline-block rounded bg-success px-3 py-2 text-xs font-medium text-gray hover:bg-opacity-90"
                      >
                        <p>Mark as</p>
                        <p>Order Shipped</p>
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
            {order &&
              order.orderDetails.map((order: any, key: any) => (
                <div className=" bg-white p-2 border-b border-[#eee] mt-1">
                  <div className="flex items-center gap-5 mb-1 text-sm my-1 text-black">
                    <img
                      src={
                        order.productImageUrl ??
                        '/assets/placeholder/order-product.svg'
                      }
                      width="64"
                      height="64"
                      className="object-cover"
                    />
                    <div className="font-medium space-y-0.5">
                      <p className="font-bold text-xs">{order.productName}</p>
                      <p>₹. {order.price}</p>
                      <div className="flex space-x-5">
                        <p>Quantity : {order.quantity}</p>
                        <p>Total : {order.amount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex  w-full justify-end pr-2 mt-1 xl:pr-8 text-black font-semibold bg-white right-0 py-3 xl:py-5 text-normal">
            <div>
              <p> Sub Total: ₹. {order.amount}</p>
              <p>
                {' '}
                Shipping Cost: ₹.{' '}
                {order.flatShipping ? order.flatShipping : '0.00'}
              </p>
              <p> Order Total : ₹. {order.orderTotal}</p>
            </div>
          </div>

          {order.status != 'ORDER_PENDING' && (
            <div className=" bg-white p-3  xl:px-6 mt-3 ">
              <h2 className="text-lg md:text-xl xl:text-xl font-semibold text-black py-1 mb-2">
                Payment gateway details:
              </h2>
              <div className=" text-black">
                <div className=""> Payment gateway : HDFC</div>
                <div className="">
                  Payment Id : {order.paymentId ? order.paymentId : 'N/A'}
                </div>
                <div className="">Order Id : {order.paymentOrderId}</div>
                <div className="">Status : {order.paymentStatus}</div>
              </div>
            </div>
          )}
          {order.shippingAddress == 'storePickup' ? (
            <div className=" bg-white p-3  xl:px-6 mt-3 ">
              <h2 className="text-lg md:text-xl xl:text-xl font-semibold text-black py-1 mb-2">
                Delivery Address:
              </h2>
              <div className=" text-black">
                <p>
                  {customer?.firstName} {customer?.lastName}
                </p>
                <p>Order pick Up from Motoioi shop</p>
                <p>{customer?.contactNumber}</p>
              </div>
            </div>
          ) : (
            <div className=" bg-white p-3  xl:px-6 mt-3 ">
              <h2 className="text-lg md:text-xl xl:text-xl font-semibold text-black py-1 mb-2">
                Delivery Address:
              </h2>
              <div className=" text-black ">
                <p>
                  {customer?.firstName} {customer?.lastName}
                </p>

                <p>{order?.shippingAddress}.</p>

                <p>{customer?.contactNumber}</p>
              </div>
            </div>
          )}

          <div className=" bg-white p-3  xl:px-6 mt-3 mb-10">
            <h2 className="text-lg md:text-xl xl:text-xl font-semibold text-black py-1 mb-2">
              Customer Address:
            </h2>
            <div className=" text-black ">
              <p>
                {customer?.addresses[0].locality},{' '}
                {customer?.addresses[0].streetName},{' '}
                {customer?.addresses[0].city}, {customer?.addresses[0].pin},{' '}
                {customer?.addresses[0].state}, {customer?.addresses[0].country}
                .
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center font-bold">
          <p className="text-lg text-black">
            Order is not found with Order ID {orderId}
          </p>
        </div>
      )}
    </>
  );
};
export default OrderDetails;
