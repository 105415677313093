import axios from 'axios';
import { API_KEY, API_SECRET, BASEURL } from '../pages/Constants';

export const getAccessToken = (): string | null => {
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;
  const accessToken = user?.accessToken || null;
  console.log('access token', accessToken);
  return accessToken;
};

const accessToken = getAccessToken();  // Assuming you have a function to get the access token

export default axios.create({
  baseURL: `${BASEURL}`,
  // withCredentials: true,
  headers: {
    'Authorization': `Bearer ${accessToken}`,
    'Content-type': 'application/json',
    'X-API-SECRET':`${API_SECRET}`,
    'X-API-KEY':`${API_KEY}`
  },
});

// instance.interceptors.request.use((config) => {
//   const accessToken = getAccessToken();
//   if (accessToken) {
//     config.headers['Authorization'] = `Bearer ${accessToken}`;
//   }
//   console.log("config=",config);
//   return config;
// });

// export default instance;

