import http from './http-common';
class CategoryService {
  getCategory(categoryId: string) {
    return http.get(`category/${categoryId}`);
  }

  getAllCategories() {
    return http.get<any>(`category/`);
  }

  updateCategory(categoryId: any, data: any) {
    console.log(data);
    return http.post(`category/update/${categoryId}`, data);
  }

  createCategory(data: any) {
    return http.post('category/', data);
  }

  removeCategory(categoryId: any) {
    return http.get(`category/delete/${categoryId}`);
  }
}
export default new CategoryService();
