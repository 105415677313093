import axios from 'axios';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import * as Yup from 'yup';
import { API_KEY, API_SECRET, BASEURL } from '../Constants';
import { getAccessToken } from '../../services/http-common';
interface RoleInterface {
  roleId?: any | null;
  name: string;
}

interface userInterface {
  userId?: any | null;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  status: string;
  username: string;
  password: string;
  roles: RoleInterface[];
}
const UserDetails = () => {
  const [initialValues, setInitialValues] = useState<userInterface>({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    status: '',
    username: '',
    password: '',
    roles: [
      {
        name: '',
      },
    ],
  });

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email().required('Email is required'),
    contactNumber: Yup.string().required('Contact number is required'),
    status: Yup.string().required('status  is required'),
    username: Yup.string().required('Username is required'),
  });
  const userId = useParams<{ userId: string }>();
  let id = userId?.userId;

  const [user, setUser] = useState<userInterface>();
  const accessToken = getAccessToken();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get(`${BASEURL}user/get/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'X-API-SECRET': `${API_SECRET}`,
            'X-API-KEY': `${API_KEY}`,
          },
          method: 'GET',
        });
        const userData = await userResponse.data;
        console.log('user data', userData);
        setInitialValues(userData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUserData();
  }, [userId]);

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    // e.preventDefault();
    try {
      console.log('values', values);
      const response = await axios.post(`${BASEURL}user/update/${id}`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'X-API-SECRET': `${API_SECRET}`,
          'X-API-KEY': `${API_KEY}`,
        },
      });
      console.log('update', response);
      window.location.href = '/users/';
    } catch (error) {
      console.error('Error during user updating:', error);
      // window.location.href = "/users/";
    }
  };

  return (
    <>
      <Breadcrumb pageName="User Details" />

      <div className="flex flex-col gap-6">
        {/* <!-- Contact Form --> */}
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values }) => (
              <Form>
                <div className="border-b border-stroke px-6.5 py-2 dark:border-strokedark">
                  <h3 className="text-grey font-medium dark:text-white">
                    User Details{' '}
                    <span className="text-sm ">
                      ({values.roles[0].name})
                      <button className="text-primary" title="Edit Client">
                        <Link
                          className="hover:text-primary "
                          to={`/users/update/${values.userId}`}
                        >
                          <svg
                            className="feather feather-edit text-blue-600 "
                            fill="none"
                            height="30"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                          </svg>
                        </Link>
                      </button>
                    </span>
                  </h3>
                </div>
                <div className="p-6.5">
                  <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                    <div className="w-full xl:w-1/2">
                      <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                        First name
                      </label>
                      <Field
                        type="text"
                        value={values.firstName}
                        name="firstName"
                        placeholder="Enter first name"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className=" text-danger"
                      />
                    </div>

                    <div className="w-full xl:w-1/2">
                      <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                        Last name
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        placeholder="Enter last name"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className=" text-danger"
                      />
                    </div>
                  </div>

                  <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                    <div className="w-full xl:w-1/2">
                      <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                        Contact number
                      </label>
                      <Field
                        type="text"
                        name="contactNumber"
                        value={values.contactNumber}
                        placeholder="Enter contact number"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary  disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      />
                      <ErrorMessage
                        name="contactNumber"
                        component="div"
                        className=" text-danger"
                      />
                    </div>
                    <div className="w-full xl:w-1/2">
                      <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                        Email
                      </label>
                      <Field
                        type="text"
                        name="email"
                        readOnly
                        value={values.email}
                        placeholder="Enter email"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className=" text-danger"
                      />
                    </div>
                  </div>

                  <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                    <div className="w-full xl:w-1/2">
                      <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                        Username
                      </label>
                      <Field
                        type="text"
                        name="username"
                        value={values.username}
                        readOnly
                        placeholder="Enter email"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className=" text-danger"
                      />
                    </div>
                    <div className="w-full xl:w-1/2">
                      <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                        Status
                      </label>
                      <Field
                        type="text"
                        name="status"
                        readOnly
                        value={values.status}
                        placeholder="Enter contact number"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary  disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className=" text-danger"
                      />
                    </div>
                  </div>

                  <div className="w-full xl:mt-8 "></div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
